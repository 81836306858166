import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Trash2 } from "react-feather";
import Footer from "../../components/Footer";
import api from "../../api/api";
import { getCurrentUser } from "../../utils/authService";
import useDetails from "../../hooks/useDetails";
import { GetStudentDetails } from "../../api/student";
import usePayments from "../../hooks/usePayments";
import StudentInfo from "../../components/StudentInfo";

import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";

const CourseRegistration = () => {
  const { data } = useDetails();
  const paymentComplete = data?.payment_complete;
  const { payment } = usePayments();
  const [level, setLevel] = useState();
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [mappedCourses, setMappedCourses] = useState([]);
  const [registeredCourses, setRegisteredCourses] = useState([]);
  const [registeredShow, setRegisteredShow] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [validation, setValidation] = useState();
  const [rowClick, setRowClick] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [totalSelectedUnits, setTotalSelectedUnits] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState();
  const [faculty, setFaculty] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [selectedFilteredCourses, setSelectedFilteredCourses] = useState();
  const [query, setQuery] = useState("");
  const [session, setSession] = useState("");
  const [semesterDetails, setSemesterDetails] = useState([]);
  const [availableSessions, setAvailableSessions] = useState([]);
  const [
    currentSessionModificationStatus,
    setCurrentSessionModificationStatus,
  ] = useState(null);

  const theCourses = mappedCourses?.map((course) => course.course_id);

  const [grade, setGrade] = useState("");
  const [semester, setSemester] = useState();

  const [toggle, setToggle] = useState(false);

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [courseCode, setCourseCode] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const user = getCurrentUser();

  const token = user?.access;

  const totalUnits = selectedCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const totalRegisteredUnits = registeredCourses.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const onPageChange = (event) => {
    console.log(event, "<<<<<<<");
    setPagination(event.page); // API pagination typically starts at 1
  };

  const onRowsChange = (event) => {
    setPagination(1); // Reset to the first page when changing page size
    setPageSize(event.value);
  };

  const getDetails = async () => {
    setLoading(true);
    try {
      const data = await GetStudentDetails(user);
      setLevel(data?.level);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //   const fetchPaymentStatus = async () => {
  //     try {
  //       const { data } = await GetPaymentStatus(user.application_id);
  //       setAdmissionFee(data.application_fee);
  //       setResultFee(data.result_checking_fee);
  //     } catch (error) {}
  //   };

  const getCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/all/?p=${
          pagination + 1
        }&page_size=${pageSize}&semester_int=${semester}&affliate=uniben&search_string=${courseCode}`
      );
      setTotalRecords(data?.count);
      setCourses(data?.data);
    } catch (error) {}
  };

  // const getMappedCourses = async () => {
  //   try {
  //     const { data } = await api.get(
  //       `/courses/mapped/read?mapped_level=${level}&semester_int=${semester}`
  //     );
  //     // setMappedCourses(data.data);
  //     setCourses(data.data.map((course) => course.course_id));
  //   } catch (error) {}
  // };

  const getFilteredCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/mapped/read?course_id_code=${query}`
      );
      // setFilteredCourses(data.data)
      setFilteredCourses(data.data.map((course) => course.course_id));
    } catch (error) {}
  };

  const getDepartments = async () => {
    try {
      const { data } = await api.get(`/department/all?page_size=${70}`);
      setDepartments(data?.results);
    } catch (error) {}
  };

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(
        `/courses/student-view?registration_level=${level}&session=${session}`
      );
      setRegisteredCourses(data);
    } catch (error) {}
  };

  const getAvailableSession = async () => {
    try {
      const { data } = await api.get(`/setup/sessions/read`);
      setSemesterDetails(data.data);
      setAvailableSessions(data.data.map((item) => item.name).reverse());
      console.log("available sessions", data);
    } catch (error) {}
  };

  const handleFirstSemesterReset = async () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to reset your First Semester Course Registration for ${level}, ${session}?`
    );
    if (!userConfirmed) return;
    try {
      const { data } = await api.post("/courses/reset/", {
        registration_session: session,
        semester: "first",
        student_id: user,
      });
      toast.success("Reset successful", successProp);
      getRegisteredCourses();
    } catch (error) {
      toast.error("Reset failed", errorProp);
    }
  };

  const handleSecondSemesterReset = async () => {
    const userConfirmed = window.confirm(
      `Are you sure you want to reset your Second Semester Course Registration for ${level}, ${session}?`
    );
    if (!userConfirmed) return;
    try {
      const { data } = await api.post("/courses/reset/", {
        registration_session: session,
        semester: "second",
        student_id: user,
      });
      toast.success("Reset successful", successProp);
      getRegisteredCourses();
    } catch (error) {
      toast.error("Reset failed", errorProp);
    }
  };

  const MAX_UNITS = 80;

  const handleSelectionChange = (e) => {
    // Calculate the total units of the selected courses
    const newSelectedCourses = e.value;
    let totalUnits = 0;
    newSelectedCourses.forEach((theCourses) => {
      totalUnits += parseInt(theCourses.unit); // Assuming quantity represents units
    });

    // Check if the total units exceed the allowed maximum
    if (totalUnits <= MAX_UNITS) {
      setSelectedCourses(newSelectedCourses);
      setTotalSelectedUnits(totalUnits);
    } else {
      alert(`Cannot exceed maximum of ${MAX_UNITS} units`);
    }
    // If the total units exceed the limit, don't update the selectedProducts state
  };

  function isObject(value) {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  }

  const handleCourseSelection = (event) => {
    if (isObject(event)) {
      const course = event;
      const newTotalUnits = totalUnits + parseInt(course.unit);

      // Check if adding the new course will exceed the maximum units
      if (newTotalUnits > MAX_UNITS) {
        alert(`Cannot exceed maximum of ${MAX_UNITS} units!`);
        return;
      }

      // Check if course is already in selectedCourses array
      if (
        selectedCourses.some(
          (selectedCourse) => selectedCourse.id === course.id
        )
      ) {
        alert("Course already selected!");
        return;
      }

      setSelectedCourses((prevSelectedCourses) => [
        ...prevSelectedCourses,
        course,
      ]);
      setQuery("");
    } else {
      const course = JSON.parse(event.target.value);
      const newTotalUnits = totalUnits + parseInt(course.unit);

      // Check if adding the new course will exceed the maximum units
      if (newTotalUnits > MAX_UNITS) {
        alert(`Cannot exceed maximum of ${MAX_UNITS} units!`);
        return;
      }

      // Check if course is already in selectedCourses array
      if (
        selectedCourses.some(
          (selectedCourse) => selectedCourse.id === course.id
        )
      ) {
        alert("Course already selected!");
        return;
      }

      setSelectedCourses((prevSelectedCourses) => [
        ...prevSelectedCourses,
        course,
      ]);
    }
  };

  const handleCourseDelete1 = (id) => {
    let newList = selectedCourses.filter((item) => item.id !== id);
    setSelectedCourses(newList);
  };

  const validated = async () => {
    try {
      const { data } = await api.get(`/courses/validated/first/${level}`);
      setValidation(data?.data[0]);
    } catch (error) {}
  };

  const deleteRegisteredCourses = async (id) => {
    try {
      const { data } = await api.delete(
        `/courses/remove-registered-course/${id}`
      );
      getRegisteredCourses();
      toast.success("Course deleted");
    } catch (error) {}
  };

  const registerCourses = async (e) => {
    e.preventDefault();
    if (!session) return toast.info("Select a session");
    setLoading(true);
    try {
      const { data } = await api.post("/courses/register", {
        courses: selectedCourses,
        registration_level: level,
        session,
      });
      setLoading(false);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      alert(error.response.data.message);
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    console.log(_filters, "_filterrr");

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
    // if (value.length > 5) {
    setCourseCode(value);
    // }
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  useEffect(() => {
    if (!session) return;
    let data = semesterDetails.find((item) => item.name === session);
    setCurrentSessionModificationStatus(data.course_modifiable);
  }, [session, semesterDetails]);

  useEffect(() => {
    getDetails();
    getDepartments();
    getAvailableSession();
    // fetchPaymentStatus();
  }, []);

  useEffect(() => {
    getRegisteredCourses();
  }, [session]);

  useEffect(() => {
    validated();
    // getMappedCourses();
  }, [level, semester]);

  useEffect(() => {
    getFilteredCourses();
  }, [query]);

  useEffect(() => {
    getCourses();
  }, [pagination, pageSize, courseCode, semester]);

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  return (
    <div class="page-wrapper">
      {data && courses && (
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-right">
                    <ol class="breadcrumb">
                      {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                      <li class="breadcrumb-item active">Registration</li>
                    </ol>
                  </div>
                  <h4 class="page-title">Registration</h4>
                </div>
              </div>
            </div>
            {/* {(paymentComplete?.toLowerCase() === "full" || paymentComplete?.toLowerCase() === 'part') && */}
            <div class="row">
              <div class="col-12">
                <div class="tab-content detail-list" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="general_detail">
                    <div class="row">
                      <div class="col-lg-12 col-xl-12 mx-auto">
                        <div class="card">
                          <div class="card-body">
                            <div class="container">
                              <div class="form-group row">
                                <div class="col-md-6">
                                  <label>Select Registration Level</label>
                                  <select
                                    class="form-control mb-3"
                                    value={level}
                                    onChange={(e) => setLevel(e.target.value)}
                                  >
                                    <option></option>
                                    <option>100</option>
                                    <option>200</option>
                                    <option>300</option>
                                    <option>400</option>
                                    <option>500</option>
                                  </select>
                                  {/* <button className="btn dspg-success mr-1">
                                    Register
                                  </button> */}
                                </div>
                                <div class="col-md-6">
                                  <label>Select Session</label>
                                  <select
                                    class="form-control mb-3"
                                    value={session}
                                    onChange={(e) => setSession(e.target.value)}
                                  >
                                    <option></option>
                                    {availableSessions?.map(
                                      (session, index) => (
                                        <option key={index}>{session}</option>
                                      )
                                    )}
                                  </select>
                                  {/* <button className="btn dspg-success mr-1">
                                    Register
                                  </button> */}
                                </div>
                              </div>
                              <StudentInfo
                                data={data}
                                schoolFee={data?.payment_complete}
                              />
                              <>
                                <hr />
                                <p className="breadcrumb-item active">
                                  Registration Criteria
                                </p>

                                <div class="form-group row">
                                  <div class="col-md-3">
                                    <label>Semester</label>
                                    <select
                                      class="form-control"
                                      value={semester}
                                      onChange={(e) =>
                                        setSemester(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Select Semester--
                                      </option>
                                      <option value="1">FIRST</option>
                                      <option value="2">SECOND</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="form-group mb-3">
                                  <button
                                    class="btn btn-gradient-primary btn-sm  mb-0"
                                    disabled={loading}
                                    onClick={() => setRegisteredShow(true)}
                                  >
                                    View Registered Courses
                                  </button>
                                </div>
                                <hr />

                                <hr />
                                {registeredShow ? (
                                  <>
                                    {registeredCourses.length ? (
                                      <>
                                        <p className="breadcrumb-item">
                                          Registered Courses
                                        </p>
                                        <div className="alert dspg-info">
                                          <b>Note!</b> You cannot delete courses
                                          after the Course Advisor have approved
                                          your course registration{" "}
                                        </div>
                                        <table className="col-lg-9">
                                          <thead className="dspg-dark">
                                            <th>Subject</th>
                                            <th>Grade</th>
                                            <th>Unit</th>
                                            <th>Semester</th>
                                            {/* <th>Status</th> */}
                                          </thead>
                                          {registeredCourses?.map((list) => (
                                            <tbody>
                                              <td>{list.code}</td>
                                              <td>{list.title}</td>
                                              <td>{list.unit}</td>
                                              <td>{list.semester}</td>
                                              {/* <td>
                                                {list.is_approved
                                                  ? "Approved"
                                                  : "Not Approved"}
                                              </td> */}
                                            </tbody>
                                          ))}
                                          <tfoot>
                                            <tr>
                                              <td className="h5">
                                                Total Units
                                              </td>
                                              <td></td>
                                              <td className="h5">
                                                {totalRegisteredUnits}
                                              </td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td colSpan={4} className="h5">
                                                Course Advisor: {}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colSpan={4}>
                                                {/* {validation?.is_validated && ( */}
                                                {currentSessionModificationStatus && (
                                                  <>
                                                    <button
                                                      type="submit"
                                                      className="dspg-info mr-2"
                                                      onClick={
                                                        handleFirstSemesterReset
                                                      }
                                                    >
                                                      Reset First Semester
                                                    </button>
                                                    <button
                                                      type="submit"
                                                      className="dspg-dark mr-2"
                                                      onClick={
                                                        handleSecondSemesterReset
                                                      }
                                                    >
                                                      Reset Second Semester
                                                    </button>
                                                  </>
                                                )}
                                                <button
                                                  type="submit"
                                                  className="dspg-danger"
                                                  onClick={() =>
                                                    navigate(
                                                      "/course-reg/print"
                                                    )
                                                  }
                                                >
                                                  Go to Printout
                                                </button>
                                                {/* )} */}
                                              </td>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </>
                                    ) : (
                                      <div className="alert dspg-info">
                                        No Courses Registered Yet
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                              {semester ? (
                                <div className="col-lg-12">
                                  <DataTable
                                    value={courses}
                                    footer={`Total Units Selected: ${totalUnits}`}
                                    selectionMode={rowClick ? null : "checkbox"}
                                    selection={selectedCourses}
                                    onSelectionChange={handleSelectionChange}
                                    dataKey="id"
                                    tableStyle={{ minWidth: "50rem" }}
                                    loading={loading}
                                    header={header}
                                    filters={filters}
                                  >
                                    <Column
                                      selectionMode="multiple"
                                      headerStyle={{ width: "3rem" }}
                                    ></Column>
                                    <Column
                                      field="code"
                                      header="Course Code"
                                    ></Column>
                                    <Column
                                      field="title"
                                      header="Course Title"
                                    ></Column>
                                    <Column
                                      field="unit"
                                      header="Course Unit"
                                    ></Column>
                                    <Column
                                      field="semester"
                                      header="Semester"
                                    ></Column>
                                  </DataTable>
                                  <Paginator
                                    first={pagination}
                                    rows={1}
                                    totalRecords={totalRecords}
                                    onPageChange={onPageChange}
                                    onRowsChange={onRowsChange}
                                    template=" PrevPageLink CurrentPageReport NextPageLink"
                                  />
                                  <div className="d-flex justify-content-between mt-2">
                                    {/* <button className="btn dspg-warning" type="button" data-toggle="modal" data-target="#exampleModalCenter">Add more Courses</button> */}
                                    <button
                                      className="btn dspg-success"
                                      onClick={registerCourses}
                                      disabled={loading}
                                    >
                                      Register Courses
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {selectedCourses?.length ? (
                                <div className="bg-light col-lg-9">
                                  <table className="mt-3 col-lg-12">
                                    <thead className="dspg-dark">
                                      <th>Code</th>
                                      <th>Course</th>
                                      <th>Unit</th>
                                      <th>Semester</th>
                                    </thead>
                                    {selectedCourses?.map((list) => (
                                      <tbody>
                                        <td>{list.code}</td>
                                        <td>{list.title}</td>
                                        <td>{list.unit}</td>
                                        <td>{list.semester}</td>
                                      </tbody>
                                    ))}
                                    <tfoot>
                                      <tr>
                                        <td className="h5">Total Units</td>
                                        <td></td>
                                        <td className="h5">{totalUnits}</td>
                                        <td></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* } */}
          </div>

          {/* {(paymentComplete?.toLowerCase() !== 'full' && paymentComplete?.toLowerCase() !== "part") &&
          <div class="alert dspg-warning" role="alert">
          Please Pay Your School Fees First
        </div>
        } */}

          <Footer />
        </div>
      )}
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Search Courses
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {/*body */}
              <div className="form-group row">
                <div className="col-md-6 position-relative">
                  <label>Search Course Code</label>
                  <input
                    className="form-control"
                    placeholder="Search..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <div
                    className="col-md-12 position-absolute bottom-50 start-50 bg-white"
                    style={{ zIndex: "100", cursor: "pointer" }}
                  >
                    {query.length >= 2 && (
                      <>
                        {filteredCourses?.map((filtered) => (
                          <h6
                            key={filtered.id}
                            onClick={() => handleCourseSelection(filtered)}
                          >
                            {filtered.code} - {filtered.title}
                          </h6>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* <div class="form-group row">
            <div class="col-md-2">
              <label>Level</label>
              <select
                class="form-control"
                value={level}
                onChange={(e) =>
                  setLevel(e.target.value)
                }
              >
                <option>--Level--</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="400">400</option>
              </select>
            </div>
            <div class="col-md-3">
              <label>Semester</label>
              <select
                class="form-control"
                value={semester}
                onChange={(e) =>
                  setSemester(e.target.value)
                }
              >
                <option>--Select Semester--</option>
                <option value="1">FIRST</option>
                <option value="2">SECOND</option>
              </select>
            </div>
            <div class="col-md-7">
              <label>Department</label>
              <select
                class="form-control"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option>
                  --Select Department--
                </option>
                {departments?.map(({id, department}) => (
                  <option
                    key={id}
                    value={department}
                  >
                    {department}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group row">
          <div class="col-md-12">
              <label>Academic Course</label>
              <select
                class="form-control"
                value={selectedCourses}
                onChange={handleCourseSelection}
              >
                <option>
                  --Select Academic Course--
                </option>
                {filteredCourses?.map(({course_id}) => (
                  <option
                    key={course_id.id}
                    value={JSON.stringify(course_id)}
                  >
                    {course_id.code} {course_id.title}
                  </option>
                ))}
              </select>
            </div>
          </div> */}

              {selectedCourses?.length ? (
                <div className="bg-light">
                  <p className="breadcrumb-item">Selected Courses</p>
                  <table className="col-lg-12">
                    <thead className="dspg-dark">
                      <th>Code</th>
                      <th>Title</th>
                      <th>Unit</th>
                      <th>Semester</th>
                    </thead>
                    {selectedCourses?.map((list) => (
                      <tbody>
                        <td>{list.code}</td>
                        <td>{list.title}</td>
                        <td>{list.unit}</td>
                        <td>{list.semester}</td>
                        <td onClick={() => handleCourseDelete1(list.id)}>
                          <Trash2 className="waves-effect align-self-center icon-dual-pink icon-sm" />
                        </td>
                      </tbody>
                    ))}
                    <tfoot>
                      <tr>
                        <td className="h5">Total Units</td>
                        <td></td>
                        <td className="h5">{totalUnits}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              ) : (
                ""
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseRegistration;
