import React, { useEffect, useState, useRef } from "react";
import {
  Navigate,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import avatar from '../assets/images/user.png'
import logo from '../assets/images/DSCE.png'
import { AlignRight, Home, LogOut, Menu } from "react-feather";
import { getCurrentUser, removeUser } from "../utils/authService";
import useDetails from "../hooks/useDetails";
import $ from 'jquery';
import 'metismenu';
import 'metismenu/dist/metisMenu.min.css';

function PortalWrapper() {
  const [transaction, setTransaction] = useState({});
  const [passport, setPassport] = useState();
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();
  const [details, setDetails] = useState();
  const [screenSize, setScreenSize] = useState(undefined)
  let navigate = useNavigate();
  const menuRef = useRef(null);

  const {data, staging:stage} = useDetails()
  const photo = data?.photo

  const token = localStorage.getItem("student-token");
  const location = useLocation();

//   const fetchPaymentStatus = async () => {
//     try {
//       const { data } = await GetPaymentStatus(user.application_id);
//       console.log(data, "statuss");
//       setAdmissionFee(data.application_fee);
//       setResultFee(data.result_checking_fee);
//     } catch (error) {}
//   };
useEffect(() => {
  
  const handleResize = () => setScreenSize(window.innerWidth)

  window.addEventListener('resize', handleResize)

  handleResize()

  return () => window.removeEventListener('resize', handleResize)
}, [])

useEffect(()=> {
  let toggleAble = document.querySelector("body");

  if (screenSize <= 1024) {
    toggleAble.classList.add("enlarge-menu-all");
    toggleAble.classList.add("enlarge-menu");
  } else {
    toggleAble.classList.remove("enlarge-menu-all");
    toggleAble.classList.remove("enlarge-menu");
  }
},[screenSize])

useEffect(() => {
  $(menuRef.current).metisMenu();
}, []);

  if (token === null) {
    return (
      <Navigate
        to={{
          pathname: `/login`,
          state: { from: location },
        }}
        replace
      />
    );
  }
  

  const handleLogout = () => {
    localStorage.clear();
    navigate("/staff/admissions/clearance/login");
  };

  const logOut = () => {
    removeUser();
    navigate("/login");
  };

  function toggle() {
    // Left menu collapse
    // $('.button-menu-mobile').on('click', function (event) {
    //     event.preventDefault();
    //     $("body").toggleClass("enlarge-menu");
    let toggleAble = document.querySelector("body");
    toggleAble.classList.toggle("enlarge-menu");
  }

  const onImageError = (e) => {
    e.target.src = avatar;
  };

  return (
    <>
      <div class="leftbar-tab-menu">
        <div class="main-icon-menu">
          <a
            href="../analytics/analytics-index.html"
            class="logo logo-metrica d-block text-center"
          >
            <span>
              {/* <img src="../assets/images/dspg_logo.png" alt="logo-small" className="logo-sm"/> */}
            </span>
          </a>
          <nav className="nav">
            <NavLink
              to="/"
              className="nav-link"
              data-toggle="tooltip-custom"
              data-placement="right"
              title=""
              data-original-title="Analytics"
              data-trigger="hover"
            >
              <Home className="align-self-center menu-icon icon-dual" />
            </NavLink>

            {/* <a href="#MetricaApps" class="nav-link" data-toggle="tooltip-custom" data-placement="right" title="" data-original-title="Apps" data-trigger="hover">
                        <i data-feather="grid" class="align-self-center menu-icon icon-dual"></i>
                    </a>

                    <a href="#MetricaUikit" class="nav-link" data-toggle="tooltip-custom" data-placement="right" title="" data-original-title="UI Kit" data-trigger="hover">
                        <i data-feather="package" class="align-self-center menu-icon icon-dual"></i>
                    </a>

                    <a href="#MetricaPages" class="nav-link" data-toggle="tooltip-custom" data-placement="right" title="" data-original-title="Pages" data-trigger="hover">
                        <i data-feather="copy" class="align-self-center menu-icon icon-dual"></i>             
                    </a>

                    <a href="#MetricaAuthentication" class="nav-link" data-toggle="tooltip-custom" data-placement="right" title="" data-original-title="Authentication" data-trigger="hover">
                        <i data-feather="lock" class="align-self-center menu-icon icon-dual"></i>
                    </a> */}
          </nav>
          <div class="pro-metrica-end">
            <a
              href=""
              onClick={logOut}
              class="help"
              data-toggle="tooltip-custom"
              data-placement="right"
            >
              <LogOut class="align-self-center menu-icon icon-md icon-dual mb-4" />
            </a>
            <NavLink href="" class="profile">
              <img
                src={photo?photo:avatar}
                onError={onImageError}
                alt="profile-user"
                class="rounded-circle thumb-sm"
              />
            </NavLink>
          </div>
        </div>

        <div class="main-menu-inner">
          <div class="topbar-left">
            <a to="" class="logo">
              <span>
                <img
                  src={logo}
                  alt="logo-large"
                  class="logo-sm logo-dark"
                  style={{
                    width: "35%",
                    height: "35%",
                    marginLeft: "2rem",
                    marginTop: "1rem",
                  }}
                />
              </span>
            </a>
          </div>
          <div class="menu-body slimscroll">
            <div class="title-box">
              <h6 class="menu-title">My Student Portal</h6>
            </div>
            <ul ref={menuRef} className="nav metismenu">
                  {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/" end>
                      Dashboard
                    </NavLink>
                  </li> */}
                  
              <li class="nav-item">
                      <a class="nav-link" href="#"><span class="w-100">Dashboard</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                      <ul class="nav-second-level" aria-expanded="false">
                          <li><NavLink to="/" end>Home</NavLink></li>
                          <li><NavLink to="/biodata" >Biodata</NavLink></li>
                      </ul>            
                  </li>
              <li class="nav-item">
                      <a class="nav-link" href="#"><span class="w-100">Payments</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                      <ul class="nav-second-level" aria-expanded="false">
                          <li><NavLink to="/payment" end>Payments</NavLink></li>
                          <li><NavLink to="/history/payments" end>Invoice</NavLink></li>
                      </ul>            
                  </li>
                  {/* <li class="nav-item">
                      <a class="nav-link" href="#"><span class="w-100">Accommodation</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                      <ul class="nav-second-level" aria-expanded="false">
                          <li><NavLink to="/accomodation" end>Book</NavLink></li>
                          <li><NavLink to="/history/accommodation" end>Accommodation Data</NavLink></li>
                      </ul>            
                  </li> */}
                     <li className="nav-item">
                       <a class="nav-link" href="#"><span class="w-100">Course Registration</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                      <ul class="nav-second-level" aria-expanded="false">
                          <li><NavLink to="/course-reg" end>New Registration</NavLink></li>
                          <li><NavLink to="/course-reg/history" end>History</NavLink></li>
                      </ul>
                     </li>
                     {/* <li className="nav-item">
                       <NavLink className="nav-link" to="/biodata">
                         Update Biodata
                       </NavLink>
                     </li> */}
                     <li className="nav-item">
                       <NavLink className="nav-link" to="/library">
                         Library
                       </NavLink>
                     </li>
                     <li className="nav-item">
                       <NavLink className="nav-link" to="/complaint">
                         Complaint
                       </NavLink>
                     </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="topbar">
        <nav class="navbar-custom">
          <ul class="list-unstyled topbar-nav float-right mb-0">

            <li class="dropdown">
              <a
                class="nav-link dropdown-toggle waves-effect waves-light nav-user"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <img
                  src={photo?photo:avatar}
                  alt="user"
                  onError={onImageError}
                  class="rounded-circle"
                />
                <span class="ml-1 nav-user-name hidden-sm">
                  {data?.othername} <i class="mdi mdi-chevron-down"></i>{" "}
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="/biodata">
                  <i class="dripicons-user text-muted mr-2"></i> Profile
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" onClick={logOut}>
                  <i class="dripicons-exit text-muted mr-2"></i> Logout
                </a>
              </div>
            </li>
            <li class="mr-2">
              <a
                href="#"
                class="nav-link"
                data-toggle="modal"
                data-animation="fade"
                data-target=".modal-rightbar"
              >
                {/* <AlignRight class="align-self-center" /> */}
              </a>
            </li>
          </ul>

          <ul class="list-unstyled topbar-nav mb-0">
            <li>
              <a href="../analytics/analytics-index.html">
                <span class="responsive-logo">
                  <img
                    src={logo}
                    alt="logo-small"
                    class="logo-sm align-self-center"
                    height="34"
                  />
                </span>
              </a>
            </li>
            <li>
              <button class="button-menu-mobile nav-link waves-effect waves-light">
                <Menu onClick={toggle} class="align-self-center"></Menu>
              </button>
            </li>
            <li class="hide-phone app-search">
              <h4>Delta State College of Education, Mosogar</h4>
            </li>
          </ul>
        </nav>
      </div>
      <Outlet />
    </>
  );
}

export default PortalWrapper;
